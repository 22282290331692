<template>
  <div class="database">
    <div class="searchBox">
      <el-input
        :placeholder="i18nData.placehold"
        v-model="search"
        class="input-search"
        @keyup.enter.native="toSearch"
      >
        <el-button
          class="button-search"
          slot="append"
          icon="el-icon-search"
          @click="toSearch"
        ></el-button>
      </el-input>
    </div>
    <div class="menuBox">
      <el-menu
        :default-active="databaseBar"
        class="el-menu-demo elmenu"
        mode="horizontal"
        @select="handleSelect"
        background-color="#0B6FBC"
        text-color="#dddddd"
        active-text-color="#fff"
      >
        <el-menu-item
          class="elmenuitem"
          v-for="item in tabList"
          :key="item.id"
          :index="String(item.id)"
          >
          <!-- {{locale == 'en-US'?item.diyname:item.title }} -->
          {{item.diyname}}
          </el-menu-item
        >
      </el-menu>
    </div>
    <div class="databaseBox">
      <databaseList :archives-list="archivesList"></databaseList>
      <div class="noneBox" v-if="!archivesList.length>0">
        <el-image
          style="width: 20vh; height: 20vh;"
          :src="require('./../../public/img/mycollection/img_none.png')"
          fit="cover"
        >
          <div slot="placeholder" class="image-slot">
            加载中<span class="dot">...</span>
          </div>
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
        </el-image>
        <!-- <img src="./../../public/img/mycollection/img_none.png" alt=""> -->
        <span>该分类暂无内容，请联系我们</span>
        <span>联系电话 <el-link type="primary">{{phone}}</el-link></span>
      </div>
    </div>
    <div class="pagination" v-show="archivesList.length>0">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-size="pageSize"
        layout="prev, pager, next, jumper"
        :total="total"
        background
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import databaseList from "./../components/databaseList.vue";
import { toTop,search } from "./../common/top.mixin.js";

export default {
  name: "database",
  mixins: [toTop,search],
  components: {
    databaseList
  },
  data() {
    return {
      databaseBar: "0",
      total: 100,
      pageSize: 10,
      currentPage: 1,
      archivesList: [],
      channel: {},
      bannerList: [],
      tabList: [
        {
          id: 0,
          diyname: "All",
          title: "全部"
        },
        {
          id: 10,
          diyname: "PAHs",
          title: "多环芳烃"
        },
        {
          id: 9,
          diyname: "EDCs",
          title: "内分泌干扰"
        },
        {
          id: 8,
          diyname: "PPCPs",
          title: "个人护理"
        },
        {
          id: 26,
          diyname: "DBPs",
          title: "消毒副产物"
        },
        {
          id: 25,
          diyname: "PFAS",
          title: "烷基类物质"
        },
        {
          id: 27,
          diyname: "OTHERs",
          title: "其他"
        }
      ]
    };
  },
  computed: {
    phone() {
      if (this.$store.state.vuex_config.config) {
        return this.$store.state.vuex_config.config.phone_number;
      }
      return false;
    }
  },
  created() {
    this.$store.state.activeIndex = this.$route.path;
    let databaseBar = this.$route.query.databaseBar;
    if (databaseBar) {
      this.databaseBar = databaseBar;
    }
    this.getCategory();
    this.getArchives();
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.databaseBar = key;
      this.currentPage = 1;
      this.getArchives();
    },
    // 分页处理
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(val);
      this.currentPage = val;
      this.getArchives();
      this.backTop();
    },
    async getArchives() {
      // 这里用try catch包裹，请求失败的时候就执行catch里的
      try {
        let params = {
          page: this.currentPage,
          channel: this.databaseBar,
          model: 2,
          menu_index: 1
        };
        let res = await this.$api.https.getArchives(params);
        console.log(res);
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return;
        }

        let { pageList, channel } = res.data;
        this.channel = channel;
        this.total = pageList.total;
        this.archivesList = [...pageList.data];
      } catch (err) {
        console.log(err);
      }
    },
    async getCategory() {
      // 这里用try catch包裹，请求失败的时候就执行catch里的
      try {
        let params = {
          channel: -1,
          model: 2,
          menu_index: 1
        };
        let res = await this.$api.https.getCategory(params);
        console.log(res);
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return;
        }
        if (res.code == 1) {
          this.tabList = res.data;
        } else {
          this.$message({
            message: res.msg
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.database {
  background-color: #ffffff;
  line-height: 24px;
  text-align: left;
  .searchBox {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d2d2d2;
    .input-search {
      width: 487px;
      height: 30px;
      border-radius: 5px;
    }
    .button-search {
      background-color: #fff;
    }
  }
  .menuBox {
    .elmenu {
      display: flex;
      justify-content: space-between;
      ::v-deep .el-menu-item {
        flex: 1;
        text-align: center;
      }
    }
    ::v-deep .el-menu--horizontal > .el-menu-item.is-active {
      font-weight: bold;
    }
  }
  .databaseBox {
    padding: 0 50px;
    .noneBox{
      padding: 40px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 20vh;
        height: 20vh;
      }
      span {
        margin-top: 10px;
        color: #a1a1a1;
        font-size: 14px;
        // border-bottom: 1px solid #dcdfe6;
      }
    }
  }
}
</style>
