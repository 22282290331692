<template>
  <div class="listBox" v-loading="!(list.length>=0)">
    <div
      class="listItem"
      v-for="(item, index) in list"
      :index="index"
      :key="item.id"
      @click="toPage(item.id)"
    >
      <template v-if="(index + 1) % 4 != 0">
        <div class="left">
          <el-image style="width: 150px; height: 150px;border-radius: 5px;" :src="item.image" fit="cover" lazy>
            <div slot="placeholder" class="image-slot">
              加载中<span class="dot">...</span>
            </div>
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
          <!-- <img :src="item.image" alt="" /> -->
        </div>
      </template>
      <div class="right">
        <div class="title">
          {{ item.title }}
        </div>
        <div class="tip">
          {{ item.description }}
        </div>
        <div class="date">
          <div>{{ item.create_date }}</div>
          <div class="other">
            <span>{{i18nData.seeall||"See All Statistics"}}</span><span>></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "databaseList",
  props: {
    archivesList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  computed: {
    list() {
      if (this.archivesList.length > 0) {
        return this.archivesList;
      }
      return this.defaultList;
    }
  },
  data() {
    return {
      defaultList: [
        // {
        //   id: 7,
        //   image: require("./../../public/img/database/img1.png"),
        //   title:
        //     "A voluntary group composed of teachers and students from Tianjin University decorated Tanchang county in Gansu province by painting",
        //   description:
        //     "A voluntary group composed of teachers and students from Tianjin University decorated Tanchang county in Gansu province by painting A voluntary group composed of teachers and students from Tianjin University decorated Tanchang county in Gansu province by painting A voluntary group composed of teachers and students from Tianjin University decorated Tanchang county in Gansu province by painting",
        //   create_date: "December 26, 2018"
        // }
      ]
    };
  },
  methods: {
    toPage(index) {
      this.$router.push({
        path: "/detail",
        query: {
          id: index
        }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$vue_color: #9b0000;
@mixin other() {
  .other {
    display: flex;
    align-items: center;
    color: #9b0000;
    span:nth-child(1) {
      font-size: 10px;
      font-weight: bold;
    }
    span:nth-child(2) {
      padding-left: 10px;
      font-size: 24px;
    }
  }
}
.listBox {
  // min-height:60vh;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 10px;
  padding: 0 0;
  .listItem {
    display: flex;
    align-items: center;
    padding: 40px 0;
    border-bottom: 1px solid #eeeeee;
    cursor: pointer;
    .left {
      padding: 0 20px 0 0;
      ::v-deep img {
        width: 150px;
        height: 150px;
        transition: 0.5s ease;
      }
      ::v-deep img:hover {
        transform: scale(1.5, 1.5);
      }
      ::v-deep .image-slot{
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
    }
    .right {
      flex: 1;
      display: flex;
      box-sizing: border-box;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      .title {
        font-size: 14px;
        font-weight: bold;
        color: $vue_color;
      }
      .tip {
        margin: 10px 0;
        color: #838383;
      }
      .date {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .other {
          justify-content: flex-end;
        }
        @include other;
      }
    }
  }
}
</style>
